import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/app/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppStoreProvider"] */ "/app/src/providers/app-store-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/app/src/trpc/react.tsx");
